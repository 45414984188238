import "./footer.css";

function Footer() {
    return (
        <footer>
            <div>
                <b class="copy">Copyright © 2024 - Tygo Jedema</b>
            </div>
        </footer>
    );
}

export default Footer;